import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section contact">
        <h2><strong>Contact Us:</strong></h2>
          <ul>
            <li>Email: info@nzubo.com</li>
            <li>Phone: +260 777 21 06 41</li>
            <li>Address: Lusaka, Zambia</li>
          </ul>
        </div>
        <div className="footer-section social">
          <h2><strong>Follow Us:</strong></h2>
          <ul>
            <li><a href="https://web.facebook.com/KhahZed/">Facebook</a></li>
            <li><a href="https://x.com/NZUBO_KHAH">Twitter</a></li>
            <li><a href="https://www.linkedin.com/company/103529673/">LinkedIn</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
