import React from 'react';
import './Home.css';
import family from '../assets/images/fam.jpeg';
import black from '../assets/images/black.jpeg';
import bnb from '../assets/images/bnb.jpeg';
import earn from '../assets/images/earn.jpeg';
import '../App.css';

import { FaApple, FaGooglePlay } from 'react-icons/fa';

const LandingPage = () => {
    return (
      <div>
        <div className="hero-container" id='home'>
          <img
            src={family}
            alt="Hero"
            className="hero-image"
          />
          <div className="button-container" >
            <button className="btn btn-google-success" style={{ display: "flex", alignItems: "center" }}>
              <FaApple size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on iOS
              </a>
            </button>
  
            <button className="btn btn-google-primary" style={{ display: "flex", alignItems: "center" }}>
              <FaGooglePlay size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on Android
              </a>
            </button>
  
          </div>
          <div className="tagliner">
            <h2>Stays for your family</h2>
          </div>
        </div>
  
        <div className="hero-container" id='family'>
          <img
            src={black}
            alt="Hero"
            className="hero-image"
          />
          {/* <div className="button-container">
            <button className="btn btn-google-success" style={{ display: "flex", alignItems: "center" }}>
              <FaApple size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on iOS
              </a>
            </button>
  
            <button className="btn btn-google-primary" style={{ display: "flex", alignItems: "center" }}>
              <FaGooglePlay size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on Android
              </a>
            </button>
  
          </div> */}
          <div className="tagliner">
            <h2>Share moments together</h2>
          </div>
        </div>
  
        <div className="hero-container" id='bnb'>
          <img
            src={bnb}
            alt="Hero"
            className="hero-image"
          />
         {/* <div className="button-container">
            <button className="btn btn-google-success" style={{ display: "flex", alignItems: "center" }}>
              <FaApple size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on iOS
              </a>
            </button>
  
            <button className="btn btn-google-primary" style={{ display: "flex", alignItems: "center" }}>
              <FaGooglePlay size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on Android
              </a>
            </button>
  
          </div> */}
          <div className="tagliner">
            <h2>Bed & Breakfast</h2>
          </div>
        </div>
  
        <div className="hero-container" id='host'>
          <img
            src={earn}
            alt="Hero"
            className="hero-image"
          />
          {/* <div className="button-container">
            <button className="btn btn-google-success" style={{ display: "flex", alignItems: "center" }}>
              <FaApple size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on iOS
              </a>
            </button>
  
            <button className="btn btn-google-primary" style={{ display: "flex", alignItems: "center" }}>
              <FaGooglePlay size={20} color="white" style={{ marginLeft: "10px", marginRight: "10px" }} />
              <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                Download on Android
              </a>
            </button>
  
          </div> */}
          <div className="tagliner">
            <h2>Earn your way </h2>
          </div>
        </div>
      </div>
    );
  };

  export default LandingPage;