import React from 'react';
import './TSStyle.css'; // Import the CSS file

function TandC() {
  return (
    <div>

      <div className="thero-container" id="bnb">
        <div className="thero-image-tandc"></div>
        <div className="ttaglinerTandC">
          <h2>Your Terms and Conditions</h2>
        </div>
        <ul>
          <li>
            <h4>Acceptance of Terms:</h4>
            <p>
             Please read the Terms of Use on Nzubo.  </p>
          </li>
          <li>
            <h4>Sign-Up Legal Compliance:</h4>
            <p>
              You agree to provide Proof of Residence and Your Phone Number on Nzubo before usage.
            </p>
          </li>
          <li>
            <h4>Accommodation Listings:</h4>
            <p>
              By listing your accommodation on Nzubo, you agree to abide by the following terms and conditions.
              These terms constitute a legally binding agreement between you (the "Provider" or "Business") and Nzubo (the "Company").
            </p>
          </li>
          <li>
            <h4>Booking Confirmation:</h4>
            <p>
              As a Provider, you acknowledge that once a booking request is received through Nzubo, it is your responsibility to confirm or decline the reservation within the specified timeframe. Failure to respond within the designated period may result in automatic cancellation of the booking request.
            </p>
          </li>
          <li>
            <h4>Accommodation Availability:</h4>
            <p>
              You agree to keep the availability calendar on Nzubo up-to-date. In case the accommodation becomes unavailable due to unforeseen circumstances, you must promptly notify the affected customer and Nzubo to arrange an alternative solution or refund, as appropriate.
            </p>
          </li>
          <li>
            <h4>Property Condition and Compliance:</h4>
            <p>
              You are responsible for ensuring that the accommodations you offer meet all relevant legal and safety requirements. The properties should be maintained in good condition and comply with applicable local laws, regulations, and licensing requirements.
            </p>
          </li>
          <li>
            <h4>Customer Service:</h4>
            <p>
              As a Provider, you agree to provide high-quality customer service to guests, including promptly addressing their inquiries, concerns, or complaints. Nzubo may provide you with guidelines or suggestions for delivering excellent customer service.
            </p>
          </li>
          <li>
            <h4>Reviews and Ratings:</h4>
            <p>
              Customers will have the opportunity to review and rate their experience with the accommodations and services provided by you. You acknowledge that Nzubo may display these reviews and ratings on its platform, and they may impact your reputation and future bookings.
            </p>
          </li>
          <li>
            <h4>Termination:</h4>
            <p>
              Nzubo reserves the right to terminate your listing and access to its platform at any time, without prior notice, in case of non-compliance with these terms and conditions, fraudulent activities, misconduct, or any other violations of the agreement.
            </p>
          </li>
          <li>
            <h4>Modifications:</h4>
            <p>
            Nzubo may update or modify these terms and conditions from time to time. Any changes will be communicated to you via email or through the platform. Continued use of the Nzubo platform after receiving the notification constitutes your acceptance of the updated terms.
            </p>
          </li>

        </ul>
      </div>
    </div>
  );
}

export default TandC;
