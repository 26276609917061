import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.png';
import './Navbar.css';

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);

  const handleToggle = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-brand-container">
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                alt="Nzubo"
                className="navbar-logo"
              />
              Nzubo
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon">&#9776;</span>
          </button>
          <div className={`navbar-menu ${menuActive ? 'active' : ''}`}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/terms-and-conditions" className="nav-link">
                  Terms and Conditions
                </Link>
              </li>
              {/* Additional links can be added here */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
