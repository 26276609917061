import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import your components
import Home from './components/Home';
import Pages from './pages/Home';
import Product from './components/Product';
/* import About from './components/About';
import Contact from './components/Contact'; */
import TandC from './components/TandC';
 import Navbar from './components/Navbar';
 import Footer from './components/Footer';

const App = () => {
  return (
    <Router> 
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/product" element={<Product />} />
        {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
        <Route path="/terms-and-conditions" element={<TandC />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
