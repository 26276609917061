import React from 'react';
import './Home.css';
import family from '../assets/images/fam.jpeg';
import black from '../assets/images/black.jpeg';
import bnb from '../assets/images/bnb.jpeg';
import earn from '../assets/images/list.jpeg';
import '../App.css';

import { FaApple, FaGooglePlay } from 'react-icons/fa';

const LandingPage = () => {
    return (
        <div className="home-container">
            <div className="download-container">
                <button className="btn btn-dark">
                    <FaApple size={20} style={{ marginRight: "10px" }} />
                    <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Download on iOS
                    </a>
                </button>
                <button className="btn btn-google-success">
                    <FaGooglePlay size={20} style={{ marginRight: "10px" }} />
                    <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Download on Android
                    </a>
                </button>
            </div> 
            <div className="welcome-item">
                Welcome to Nzubo
            </div>

            <div className="grid-container">
               

                <div className="grid-item" id='bnb'>
                    <img src={bnb} alt="Hero" className="hero-image" />
                    <div className="tagliner">
                        <h2>Book a Stay</h2>
                    </div>
                    <div>
                        Bed & Breakfast | Apartments | Long Stays
                    </div>
                </div>
                
                <div className="grid-item" id='home'>
                    <img src={family} alt="Hero" className="hero-image" />
                    <div className="tagliner">
                        <h2>Find a home</h2>
                    </div>
                    <div>
                        Houses | Apartments | Flats
                    </div>
                </div>

                <div className="grid-item" id='family'>
                    <img src={black} alt="Hero" className="hero-image" />
                    <div className="tagliner">
                        <h2>Share life's moments</h2>
                    </div>
                    <div>
                        Family | Vacations | Get-aways
                    </div>
                </div>

                <div className="grid-item" id='host'>
                    <img src={earn} alt="Hero" className="hero-image" />
                    <div className="tagliner">
                        <span>List your Properties</span>
                    </div>
                    <div>
                        Bed & Breakfast | Rentals | Sales
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
